import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import Proyecto1 from '../images/CCEGYT PROYECTO.png'
import proyecto2 from '../images/andersen/HOME.png'

function Home() {
  useEffect(() => {
    const serviceItems = document.querySelectorAll('.service-item');
    serviceItems.forEach((item, index) => {
      item.style.animationDelay = `${index * 0.2}s`;
      item.classList.add('appear');
    });
  }, []);

  // Proyectos en curso
  const proyectos = [
    {
      id: 1,
      nombre: 'CCEGYT',
      descripcion: 'Creación de sitio web para universidad especializada en salud.',
      imagen: Proyecto1, // Reemplaza con la ruta correcta
    },
    {
      id: 2,
      nombre: 'Sistema de Gestión Deportiva',
      descripcion: 'Plataforma web para la gestion de eventos deportivos a nivel de bachillerato.',
      imagen: proyecto2, // Reemplaza con la ruta correcta
    },
  ];

  return (
    <div>
      {/* Sección Hero */}
      <header id="hero">
        <h1>Bienvenido a JL CodeDev</h1>
        <p>Ofrecemos soluciones personalizadas para tu negocio. ¿Listo para llevar tu proyecto al siguiente nivel?</p>
        <Link to="/contact" className="btn">Contáctanos</Link>
      </header>

      {/* Sección de Servicios */}
      <section id="services">
        <h2>Nuestros Servicios</h2>
        <p>Ofrecemos una variedad de servicios que incluyen desarrollo web, diseño gráfico, y consultoría técnica.</p>
        <ul>
          <li className="service-item">
            <span className="service-text">Desarrollo Web</span>
            <span className="service-icon" role="img" aria-label="code">💻</span>
          </li>
          <li className="service-item">
            <span className="service-text">Diseño Gráfico</span>
            <span className="service-icon" role="img" aria-label="paint">🎨</span>
          </li>
          <li className="service-item">
            <span className="service-text">Consultoría Técnica</span>
            <span className="service-icon" role="img" aria-label="tools">🔧</span>
          </li>
          <li className="service-item">
            <span className="service-text">Proyectos Personalizados</span>
            <span className="service-icon" role="img" aria-label="project">📊</span>
          </li>
          <li className="service-item">
            <span className="service-text">Soporte Técnico</span>
            <span className="service-icon" role="img" aria-label="support">🎧</span>
          </li>
        </ul>
      </section>

      {/* Sección de Proyectos en Curso */}
      <section id="proyectos-en-curso">
        <h2 className="titulop">Proyectos</h2>
        <div className="proyectos-lista">
          {proyectos.map((proyecto) => (
            <div key={proyecto.id} className="proyecto-card">
              <div className="proyecto-card-inner">
                <div className="proyecto-card-front">
                  <img src={proyecto.imagen} alt={proyecto.nombre} className="proyecto-imagen" />
                  <h3>{proyecto.nombre}</h3>
                </div>
                <div className="proyecto-card-back">
                  <h3>{proyecto.nombre}</h3>
                  <p>{proyecto.descripcion}</p>
                  <Link to={`/proyectos/${proyecto.id}`} className="btn">Ver Proyecto</Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Sección de Testimonios */}
<section id="testimonials">
  <h2>Testimonios</h2>
  
  <div className="testimonial">
    <p>“JL CodeDev transformó completamente nuestra escuela con la pagina que crearon. ¡Totalmente recomendado!”</p>
    <h4>- Alejandra Castañeda</h4>
  </div>
  
  <div className="testimonial">
    <p>“La mejor experiencia de desarrollo web que hemos tenido. ¡Excelentes resultados y equipo profesional!”</p>
    <h4>- Sofía N</h4>
  </div>
</section>
    </div>
  );
}

export default Home;

import React, { useEffect } from 'react';
import './About.css';
import { Link } from 'react-router-dom';

function About() {
    const beneficios = [
        {
            titulo: 'Soluciones Personalizadas',
            descripcion: 'Cada cliente es único y nosotros adaptamos nuestros servicios a las necesidades específicas de su empresa o institución. Nos tomamos el tiempo para entender su visión y crear soluciones que verdaderamente le aporten valor.',
            icono: '🌟',
        },
        {
            titulo: 'Flexibilidad',
            descripcion: 'Nos adaptamos a los tiempos y exigencias de su proyecto. Ya sea que necesite una implementación rápida o un proyecto a largo plazo, estamos listos para acompañarlo en cada etapa del proceso.',
            icono: '📅',
        },
        {
            titulo: 'Soporte Continuo',
            descripcion: 'No solo desarrollamos la solución, sino que brindamos soporte y mantenimiento continuo, asegurando que todo funcione de manera fluida y eficiente.',
            icono: '🔧',
        },
        {
            titulo: 'Precios Competitivos',
            descripcion: 'Ofrecemos precios justos y competitivos que se ajustan a su presupuesto, sin comprometer la calidad del trabajo.',
            icono: '💵',
        },
        {
            titulo: 'Ahorro de Tiempo y Recursos',
            descripcion: 'Nuestras soluciones optimizan sus procesos internos, reduciendo el tiempo dedicado a tareas repetitivas o ineficientes. Esto permite que su equipo se concentre en actividades más estratégicas.',
            icono: '⏰',
        },
    ];

    // Hook para la animación cuando los elementos entran en el viewport
    useEffect(() => {
        const beneficioItems = document.querySelectorAll('.beneficio-item');
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, { threshold: 0.3 });

        beneficioItems.forEach((item) => observer.observe(item));

        return () => {
            beneficioItems.forEach((item) => observer.unobserve(item)); // Limpiar el observer cuando se desmonta el componente
        };
    }, []);

    return (
        <section id="sobre-nosotros">
            {/* Sección "¿Quiénes somos?" */}
            <div className="quienes-somos">
                <h2>¿Quiénes somos?</h2>
                <p>
                    Somos un equipo de profesionales apasionados por la tecnología y la innovación, con años de experiencia en el desarrollo de soluciones tecnológicas que han transformado procesos internos y mejorado la experiencia digital de muchas empresas y organizaciones educativas.
                    <br /><br />
                    Nuestra consultora está enfocada en ofrecer soluciones que no solo resuelvan problemas técnicos inmediatos, sino que también aporten valor a largo plazo a nuestros clientes. Contamos con un equipo multidisciplinario que incluye desarrolladores, expertos en soporte técnico y estrategas de soluciones tecnológicas.
                    <br /><br />
                    Nos enorgullecemos de trabajar mano a mano con nuestros clientes, brindando un servicio personalizado y comprometido con la excelencia.
                </p>
            </div>

            {/* Sección "Beneficios de Trabajar con Nosotros" */}
            <div className="beneficios-section">
                <h2>Beneficios de Trabajar con Nosotros</h2>
                <div className="beneficios-grid">
                    {beneficios.map((beneficio, index) => (
                        <div key={index} className={`beneficio-item ${index % 2 === 0 ? 'left' : 'right'}`}>
                            <div className="icono">{beneficio.icono}</div>
                            <div className="contenido">
                                <h3>{beneficio.titulo}</h3>
                                <p>{beneficio.descripcion}</p>
                            </div>
                        </div>
                    ))}
                </div>
                {/* Botón que redirige a la sección de servicios */}
                <div className="boton-servicios">
                <Link to="/Services" className="btn">Ver nuestros servicios</Link>
                </div>
            </div>
        </section>
    );
}

export default About;

import React from 'react';
import './Proyectos.css'; // Usamos el mismo archivo de estilos que Proyecto2
import { FaCheckCircle } from 'react-icons/fa'; // Importa íconos de react-icons

const Proyecto1 = () => {
    return (
        <div className="proyecto2-container">
            <section className="proyecto2-hero">
                <div className="proyecto2-overlay">
                    <h1>🏫 Página Web para Escuela de Salud</h1>
                    <p className='Desp'>Impulsando la visibilidad y proyección de CCEGYT</p>
                </div>
            </section>

            <section id="project-description" className="proyecto2-fade-in">
                <div className="proyecto2-content">
                    <h2 className='Titulo-p'>Descripción del Proyecto</h2>
                    <p className='Desp'>
                        Este proyecto consiste en el desarrollo de una página web moderna para CCEGYT, una institución educativa en salud. 
                        La web cuenta con vistas como Home, Sobre Nosotros, Oferta Educativa y Contacto, permitiendo a la institución mejorar su alcance y proyectar una imagen profesional en línea. 🌐
                    </p>
                </div>
            </section>

            <section id="features" className="proyecto2-slide-in">
                <div className="proyecto2-content">
                    <h2 className='Titulo-p'>🚀 Funcionalidades Principales</h2>
                    <h3>Home</h3>
                    <p className='Desp'>
                        La página de inicio ofrece una introducción a la misión y visión de la escuela, junto con noticias y eventos destacados.
                    </p>

                    <h3>Sobre Nosotros</h3>
                    <p className='Desp'>
                        Una sección dedicada a la historia de la institución y sus logros, brindando a los visitantes un vistazo a los valores de la escuela.
                    </p>

                    <h3>Oferta Educativa</h3>
                    <ul>
                        <li><FaCheckCircle className="check-icon" /> <strong>Programas Académicos:</strong> Información detallada de cada curso y programa ofrecido.</li>
                        <li><FaCheckCircle className="check-icon" /> <strong>Certificaciones:</strong> Listado de certificaciones y opciones de formación continua.</li>
                    </ul>

                    <h3>Contacto</h3>
                    <p className='Desp'>
                        Una página de contacto donde los interesados pueden hacer consultas directas y obtener la información de contacto de la institución. 📞
                    </p>
                </div>
            </section>

            <section id="benefits" className="proyecto2-fade-in">
                <div className="proyecto2-content">
                    <h2 className='Titulo-p'>🎯 Beneficios del Proyecto</h2>
                    <ul>
                        <li><FaCheckCircle className="check-icon" /> <strong>Mejor Imagen en Línea:</strong> Una presencia digital profesional para captar más estudiantes.</li>
                        <li><FaCheckCircle className="check-icon" /> <strong>Mayor Accesibilidad a la Información:</strong> Facilita el acceso a la oferta educativa y los valores institucionales.</li>
                        <li><FaCheckCircle className="check-icon" /> <strong>Aumento del Alcance:</strong> Atrae a más estudiantes y aumenta el reconocimiento en el sector educativo.</li>
                    </ul>
                </div>
            </section>

            <section id="impact" className="proyecto2-slide-in">
                <div className="proyecto2-content">
                    <h2 className='Titulo-p'>✨ Impacto Esperado</h2>
                    <p className='Desp'>
                        Con esta página web, la escuela mejorará su presencia digital, logrando captar más estudiantes y permitiendo a los visitantes obtener 
                        toda la información necesaria sobre los programas académicos. 🌟
                    </p>
                </div>
            </section>

            <section id="technologies" className="proyecto2-fade-in">
                <div className="proyecto2-content">
                    <h2 className='Titulo-p'>🛠️ Tecnologías Utilizadas</h2>
                    <ul>
                        <li><strong>Frontend:</strong> React para una interfaz escalable y dinámica.</li>
                    </ul>
                </div>
            </section>
        </div>
    );
};

export default Proyecto1;

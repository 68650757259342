import React from 'react';
import './Proyectos.css'; // Asegúrate de crear este archivo para los estilos
import { FaCheckCircle } from 'react-icons/fa'; // Importa íconos de react-icons

const Proyecto2 = () => {
    return (
        <div className="proyecto2-container">
            <section className="proyecto2-hero">
                <div className="proyecto2-overlay">
                    <h1>🌐 Plataforma de Gestión Integral</h1>
                    <p className='Desp'>Transformando la administración de eventos y usuarios en el Instituto Andersen</p>
                </div>
            </section>

            <section id="project-description" className="proyecto2-fade-in">
                <div className="proyecto2-content">
                    <h2 className='Titulo-p'>Descripción del Proyecto</h2>
                    <p className='Desp'>
                        Colaboramos con el Instituto Andersen para desarrollar una plataforma de gestión integral que facilita la administración de eventos, usuarios, y otros procesos esenciales del instituto.
                        El sistema fue diseñado para optimizar la gestión interna, mejorar la accesibilidad a la información y simplificar la administración de actividades diarias a través de un entorno digital seguro y eficiente. 🎉
                    </p>
                </div>
            </section>

            <section id="features" className="proyecto2-slide-in">
                <div className="proyecto2-content">
                    <h2 className='Titulo-p'>🚀 Funcionalidades Implementadas</h2>
                    <h3>Inicio de Sesión y Registro</h3>
                    <p className='Desp'>
                        Se implementaron funcionalidades de inicio de sesión seguro y registro de usuarios, proporcionando una autenticación fiable para garantizar la seguridad de los datos y un acceso controlado a la plataforma.
                    </p>

                    <h3>Gestión de Eventos</h3>
                    <ul>
                        <li><FaCheckCircle className="check-icon" /> <strong>Sección de Eventos:</strong> Los administradores pueden crear, modificar y eliminar eventos fácilmente desde una interfaz intuitiva.</li>
                        <li><FaCheckCircle className="check-icon" /> <strong>Inscripción a Eventos:</strong> Los usuarios pueden inscribirse en los eventos directamente a través de la plataforma.</li>
                        <li><FaCheckCircle className="check-icon" /> <strong>Resultados de Eventos:</strong> Vista detallada para ver los resultados de los eventos realizados.</li>
                    </ul>

                    <h3>CRUD de Usuarios y Roles</h3>
                    <ul>
                        <li><FaCheckCircle className="check-icon" /> <strong>Administración de Usuarios:</strong> Sistema CRUD para crear, modificar, y eliminar usuarios.</li>
                        <li><FaCheckCircle className="check-icon" /> <strong>Asignación y Gestión de Roles:</strong> Permisos específicos para cada usuario.</li>
                        <li><FaCheckCircle className="check-icon" /> <strong>Exportación de Datos a PDF:</strong> Generación de reportes sobre los usuarios y eventos.</li>
                    </ul>

                    <h3>Perfil de Usuario y Cambio de Contraseña</h3>
                    <p className='Desp'>
                        Los usuarios pueden personalizar su perfil y cambiar su contraseña de manera rápida y segura. 🔐
                    </p>
                </div>
            </section>

            <section id="benefits" className="proyecto2-fade-in">
                <div className="proyecto2-content">
                    <h2 className='Titulo-p'>🎯 Beneficios Obtenidos</h2>
                    <ul>
                        <li><FaCheckCircle className="check-icon" /> <strong>Optimización del Tiempo de Administración:</strong> Reducción de tiempo en tareas repetitivas.</li>
                        <li><FaCheckCircle className="check-icon" /> <strong>Seguridad y Control de Acceso:</strong> Acceso seguro y controlado a la información.</li>
                        <li><FaCheckCircle className="check-icon" /> <strong>Facilidad de Acceso a Información y Reportes:</strong> Mayor visibilidad y control de las actividades del instituto.</li>
                    </ul>
                </div>
            </section>

            <section id="impact" className="proyecto2-slide-in">
                <div className="proyecto2-content">
                    <h2 className='Titulo-p'>✨ Resumen de Impacto</h2>
                    <p className='Desp'>
                        El proyecto ha transformado los procesos internos del instituto, brindando un entorno digital que facilita la gestión de eventos, usuarios, y datos.
                        Esta solución ha mejorado la eficiencia operativa y la experiencia de los usuarios. 🌟
                    </p>
                </div>
            </section>

            <section id="technologies" className="proyecto2-fade-in">
                <div className="proyecto2-content">
                    <h2 className='Titulo-p'>🛠️ Tecnologías Utilizadas</h2>
                    <ul>
                        <li><strong>Frameworks:</strong> Laravel para backend para interfaces de usuario.</li>
                        <li><strong>Base de Datos:</strong> MySQL.</li>
                        <li><strong>Autenticación y Seguridad:</strong> Implementación de Spatie para roles y permisos.</li>
                        <li><strong>Generación de Reportes:</strong> Exportación a PDF utilizando bibliotecas especializadas.</li>
                    </ul>
                </div>
            </section>
        </div>
    );
};

export default Proyecto2;

import React from 'react';
import './Footer.css';
import whatsappIcon from '../images/WhatsApp_icon.png';
import instagramIcon from '../images/Instagram_logo.png';

function Footer() {
  return (
    <footer>
      <p>&copy; 2024 JL CodeDev - Todos los derechos reservados.</p>
      <a href="mailto:contacto@jlcodedev.com" className="correo">contacto@jlcodedev.com</a>
      
      {/* Contenedor de íconos sociales */}
      <div className="social-icons">
        <a
          href="https://wa.me/message/7ST4W3EMPCX3L1"
          target="_blank"
          rel="noopener noreferrer"
          title="Envíanos un mensaje por WhatsApp"
        >
          <img src={whatsappIcon} alt="WhatsApp" className="social-icon" />
        </a>
        <a
          href="https://www.instagram.com/jlco.dev/"
          target="_blank"
          rel="noopener noreferrer"
          title="Síguenos en Instagram"
        >
          <img src={instagramIcon} alt="Instagram" className="social-icon" />
        </a>
      </div>
    </footer>
  );
}

export default Footer;

import React from 'react';
import './Services.css';

// Importa las imágenes
import desarrolloWebImg from '../images/Desarrollo_Web.jpg';
import disenoUXUIImg from '../images/Diseño_UX_UI.jpg';
import mejorasSitiosWebImg from '../images/Mejora_Sitios_Web.jpg';
import soporteTecnicoImg from '../images/Soporte_Tecnico.jpg';

const servicesData = [
    {
        title: 'Desarrollo de Sitios Web',
        description: 'Creamos sitios web personalizados, responsivos y funcionales que se adaptan a tus necesidades, utilizando las últimas tecnologías para garantizar un rendimiento óptimo.',
        imageUrl: desarrolloWebImg,
    },
    {
        title: 'Diseño UX/UI',
        description: 'Diseñamos experiencias de usuario intuitivas y atractivas, centradas en el usuario para maximizar la satisfacción y la interacción en tu plataforma digital.',
        imageUrl: disenoUXUIImg,
    },
    {
        title: 'Mejoras de Sitios Web',
        description: 'Realizamos auditorías de sitios web existentes para identificar áreas de mejora y optimizar la funcionalidad y el diseño para una mejor experiencia del usuario.',
        imageUrl: mejorasSitiosWebImg,
    },
    {
        title: 'Soporte Técnico',
        description: 'Brindamos soporte técnico continuo para resolver cualquier inconveniente y garantizar que tu sitio web funcione sin problemas.',
        imageUrl: soporteTecnicoImg,
    },
];

function Services() {
    return (
        <div>
            <header id=''>
                {/*<h2>Servicios</h2>*/}
                <h2>Transforma Tu Presencia Digital</h2>
                <p>
                    En el mundo actual, un sitio web atractivo y funcional es crucial para el éxito de tu negocio. 
                    <br />
                    ¿Estás listo para llevar tu proyecto al siguiente nivel?
                </p>
                <p>
                    Descubre nuestros servicios personalizados que incluyen:
                </p>
            </header>
            <div className="services-body">
                <ul className="services-list">
                    {servicesData.map((service, index) => (
                        <li key={index} className="service-item">
                            <div className="service-text">
                                <h3>{service.title}</h3>
                                <p className='descripcion'>{service.description}</p>
                            </div>
                            <img src={service.imageUrl} alt={service.title} className="service-image" />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default Services;
